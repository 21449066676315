<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{$t("新增其他费用")}}</div>
      <div>
        <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item :label="$t('货主')">
            <client-select v-model="dataForm.client" />
          </a-form-model-item>  
          <a-form-model-item prop="receipt_order" :label="$t('入库通知单')">
            <receipt-order-select v-model="dataForm.receipt_order" :client="dataForm.client" />
          </a-form-model-item>
          <a-form-model-item prop="charge_item" :label="$t('收费项目')">
            <a-input v-model="dataForm.charge_item" />
          </a-form-model-item>
          <a-form-model-item prop="total_amount" :label="$t('费用金额(元)')">
            <a-input-number v-model="dataForm.total_amount" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { stockInExpenseRecordCreate } from "@/api/charge";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    ReceiptOrderSelect: () => import("@/components/ReceiptOrderSelect/"),
  },

  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        receipt_order: [{ required: true, message: this.$t("请选择入库通知单"), trigger: "change" }],
        charge_item: [
          { required: true, message: this.$t("请输入收费项目"), trigger: "change" },
          { max: 64, message: this.$t("超出最大长度 (64)"), trigger: "change" },
        ],
        total_amount: [{ required: true, message: this.$t("请输入收费金额"), trigger: "change" }],
      },
      loading: false,
      dataForm: { receipt_order: undefined, charge_item: undefined, total_amount: undefined },
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          stockInExpenseRecordCreate(this.dataForm)
            .then((data) => {
              this.$message.success(this.$t("新增成功"));
              this.$emit("create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.dataForm = { receipt_order: undefined, charge_item: undefined, total_amount: undefined };
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
